body {
  background-color: #f9f9f9;
}

.purple {
  color: #562c83;
}

.full-height {
  height: 100vh;
  position: relative;
}

.center {
  text-align: center;
  margin: auto;
  width: 100%;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.td-center {
  text-align: center;
}

.col {
  padding: 0;
}

.logo {
  width: 50%;
  max-width: 400px;
}

.login-form {
  width: 50%;
  max-width: 400px;
  margin: auto;
}

.login-btn {
  width: 50%;
  max-width: 200px;
  display: block;
  margin: auto;
  margin-top: 30px;
}

.login-error {
  width: 50%;
  max-width: 400px;
  margin: auto;
}

.btn-primary {
  background-color: #562c83;
  border-color: #562c83;
}

.btn-primary:hover {
  background-color: #96c471;
  border-color: #96c471;
}

.btn-secondary {
  background-color: #96c471;
  border-color: #96c471;
}

.btn-secondary:hover {
  background-color: #562c83;
  border-color: #562c83;
}

.btn-light {
  background-color: white;
  border-color: #787776;
  color: #787776;
}

.btn-light:hover {
  background-color: #787776;
  border-color: white;
  color: white;
}

.btn-link {
  color: #562c83;
  text-decoration: none;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  background-color: white;
  padding: 20px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  -webkit-box-shadow: 3px 0px 10px 0px rgba(148,148,148,1);
  -moz-box-shadow: 3px 0px 10px 0px rgba(148,148,148,1);
  box-shadow: 3px 0px 10px 0px rgba(148,148,148,1);
}

.sidebar-logo {
  width: 100%;
  margin-bottom: 20px;
}

.sidebar-button {
  background: none;
  border: none;
  margin-top: 10px;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  color: #4d4d4d;
  text-align: left;
  text-decoration: none;
  display: block;
}

.sidebar-button svg {
  margin-right: 5px;
  margin-top: -3px;
  display: inline-block;
}

.sidebar-button:hover {
  background-color: #8581b8;
  color: white;
}

.sidebar-button-selected {
  background: none;
  border: none;
  margin-top: 10px;
  background-color: #8581b8;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  color: white;
  text-align: left;
  text-decoration: none;
  display: block;
}

.sidebar-button-selected svg {
  margin-right: 5px;
  margin-top: -3px;
  display: inline-block;
}

.sidebar-bottom {
  position: absolute;
  bottom: 40px;
  left: 0;
  border-top: 1px solid #e5e5e5;
  width: 100%;
  padding: 10px;
  padding-top: 20px;
  text-align: left;
}

.sidebar-bottom-username {
  color: black;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.sidebar-bottom-email {
  color: #888;
  font-size: 12px;
  margin-bottom: 20px;
}

.sidebar-bottom-logout-btn {
  width: 100%;
}

.main-content {
  height: 100vh;
  position: relative;
  width: calc(100vw - 300px);
  margin-left: 300px;
  padding: 20px;
  padding-left: 30px;
}

.content-card {
  padding: 20px;
}

.content-card-header {
  color: #562c83;
  text-align: center;
  margin-bottom: 10px;
}

.page-header {
  color: #562c83;
  border-bottom: 1px solid #bbb;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.clinician-home-content {
  text-align: center;
  padding-top: 15vh;
}

.clinician-home-content .btn {
  min-width: 300px;
}

.page-item a {
  background-color: #96c471 !important;
  border-color: #96c471 !important;
  color: white;
}

.page-item a:hover {
  color: white;
}

.page-item.active a {
  background-color: #8581b8 !important;
  border-color: #8581b8 !important;
}

.react-bootstrap-table {
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-bottom: 20px;
}

.react-bootstrap-table table {
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 0px;
}

.react-bootstrap-table th {
  background-color: #f9f9f9;
}

.action-cell {
  text-align: right;
  padding-right: 13px;
}

.action-btn {
  min-width: 110px;
  margin-right: 20px;
  margin-left: 0px;
}

.filters {
  margin-top: 30px;
  margin-bottom: 30px;
}

.filter-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.filter-dropdown {
  flex-grow: 1;
}

.filter-dropdown button {
  width: 100%;
  text-align: left;
  padding: 10px;
}

.filter-value {
  width: calc(100% - 20px);
  display: inline-block;
}

.analytics-chart {
  padding: 20px;
}

.modal-form-actions {
  padding-top: 10px;
  text-align: right;
}

.table-actions {
  padding-top: 20px;
  text-align: right;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.highlighted {
  color: #497fb3;
  font-weight: bold;
}

.test-category {
  color: #497fb3;
  margin-top: 20px;
}

.test-category:first-child {
  margin-top: 0;
}

.test-subcategory {
  background-color: #97b2d1;
  margin-top: 20px;
}

.test-question {
  margin-top: 20px;
}

.test-answer {
  margin-top: 5px;
  margin-left: 28px;
  background: none;
  border: none;
  padding: 5px;
  text-align: left;
}

.test-answer-selected {
  margin-top: 5px;
  margin-left: 28px;
  background: none;
  border: 2px solid #497fb3;
  border-radius: 8px;
  padding: 5px;
  text-align: left;
}

.test-answer-correct {
  color: #497fb3;
  font-weight: bold;
}

.test-answer-caption {
  margin-left: 18px;
}

.test-answer-textarea {
  margin-top: 8px;
  margin-left: 28px;
  width: calc(100% - 28px);
}

.calculations-wrapper {
  margin-top: 10px;
  max-width: 100%;
  overflow-x: auto;
  font-size: 12px;
}